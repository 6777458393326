exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1KIJU{background:#151a58;padding-top:100px;padding-bottom:30px;margin-top:100px;position:relative}@media (max-width: 640px){._1KIJU{margin-top:0;padding-top:60px;padding-bottom:20px}}._1Adnu{width:210px;display:block;margin:0 auto 50px}@media (max-width: 640px){._1Adnu{margin-bottom:30px}}.QGOMJ{font-size:16px}._1DFeX{font-size:16px;margin-top:60px}@media (max-width: 640px){._1DFeX{font-size:13px}}.jPtSF{display:block;cursor:pointer;position:absolute;z-index:1;bottom:0;left:50%;transform:translateX(-50%)}@media (max-width: 640px){.jPtSF{width:50px}}\n", ""]);

// exports
exports.locals = {
	"Root": "_1KIJU",
	"Logo": "_1Adnu",
	"Description": "QGOMJ",
	"CopyRight": "_1DFeX",
	"GoToTop": "jPtSF"
};