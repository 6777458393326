import React from 'react';
import { compose, setDisplayName, withState, lifecycle } from 'recompose';
import { withI18N } from 'shared/contexts';
import withStyles from 'isomorphic-style-loader/withStyles';
import { EN } from 'shared/constants/languages';
import FlipDown from 'libs/flip-down';
import { HOME } from 'shared/constants/features';
import flipDownStyles from 'libs/flip-down_.css';
import s from './count-down.scss';

const getIsVisible = ({
  countDown: { autoPopup, timer } = {},
  currentFeature,
}) => autoPopup
  && timer > new Date().getTime() / 1000
  && (currentFeature === HOME);

export default compose(
  withI18N,
  withStyles(s, flipDownStyles),
  withState('visible', 'setVisible', getIsVisible),
  lifecycle({
    componentDidMount() {
      if (getIsVisible(this.props)) {
        try {
          new FlipDown(this.props.countDown.timer).start();
        } catch (e) {
          console.error(e);
        }
      }
    },
  }),
  setDisplayName(__filename),
)(({
  language,
  visible,
  setVisible,
  countDown: {
    titleEN,
    titleJP,
  } = {},
}) => (visible ? (
  <div className={s.Root}>
    <div className={s.Close} onClick={() => setVisible(false)}>
      <img src="/images/icons/close.png" alt="close" />
    </div>
    <div className={s.Title}>
      {language === EN ? titleEN : titleJP}
    </div>
    <div id="flipdown" className="flipdown" />
  </div>
) : <div />));
