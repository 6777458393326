exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".WahwR{text-align:center;position:fixed;top:0;left:0;width:100%;background:rgba(9,14,66,0.96);color:#ffd101;padding:30px;z-index:2}.WahwR .flipdown{margin:0 auto}._3yg4N{position:absolute;top:0;right:0;padding:30px;cursor:pointer}@media (max-width: 640px){._3yg4N{padding:10px}}._3yg4N img{width:36px}._1MMgX{font-size:24px;margin-bottom:20px}\n", ""]);

// exports
exports.locals = {
	"Root": "WahwR",
	"Close": "_3yg4N",
	"Title": "_1MMgX"
};