/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React from 'react';
import { number, string } from 'prop-types';
import { compose, setDisplayName, setPropTypes, pure, withState } from 'recompose';

export default compose(
  setPropTypes({
    height: number,
    className: string,
  }),
  pure,
  withState('catchBuster', 'setCatchBuster', null),
  setDisplayName(__filename),
)(({ height, catchBuster, setCatchBuster, className }) => (
  <img
    height={height}
    className={className}
    src={`/api/captcha?${catchBuster}`}
    alt="captcha"
    onClick={() => setCatchBuster(new Date().toISOString())}
    style={{ cursor: 'pointer' }}
  />
));
