import React from 'react';
import { compose, setDisplayName } from 'recompose';
import { withI18N } from 'shared/contexts';
import withStyles from 'isomorphic-style-loader/withStyles';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import jump from 'jump.js';
import s from './footer.scss';

export default compose(
  withI18N,
  withStyles(s),
  setDisplayName(__filename),
)(({ i18n }) => (
  <div className={s.Root}>
    <Container>
      <Row>
        <Col md={2} />
        <Col md={8}>
          <img className={s.Logo} src="/images/logo-white.png" alt="LinkMine" />
          <div className={s.Description}>
            {i18n('footerDescription', { br: <br /> })}
          </div>
        </Col>
      </Row>
      <Row>
        <Col className={s.CopyRight}>
          Copyright By <span style={{ color: 'white' }}>LinkMine.</span>
        </Col>
      </Row>
    </Container>
    <img
      className={s.GoToTop}
      onClick={() => jump('body')}
      src="/images/home/arrow-up.png"
      alt="Up"
    />
  </div>
));
