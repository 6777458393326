import React from 'react';
import { func } from 'prop-types';
import { compose, setDisplayName, setPropTypes, pure } from 'recompose';
import { withI18N } from 'shared/contexts';
import { reduxForm, Field } from 'redux-form';
import { LOGIN } from 'shared/constants/form-name';
import { createOnSubmit } from 'relient/form';
import Input from 'shared/components/fields/input';
import { success } from 'shared/components/message';
import withStyles from 'isomorphic-style-loader/withStyles';
import Captcha from 'shared/components/captcha';
import { username } from 'shared/utils/validators';
import classNames from 'classnames';
// eslint-disable-next-line css-modules/no-unused-class
import s from './form.scss';

export default compose(
  withI18N,
  setPropTypes({
    requestChangePassword: func,
    onSuccess: func,
  }),
  pure,
  reduxForm({
    form: LOGIN,
    onSubmit: createOnSubmit(async (values, _, { requestChangePassword, onSuccess }) => {
      await requestChangePassword(values);
      onSuccess(values);
      success('The verification code is send, please check your email and reset');
    }),
  }),
  withStyles(s),
  setDisplayName(__filename),
)(({ handleSubmit, submitting, i18n }) => (
  <form onSubmit={handleSubmit} className={s.Form}>
    <div>
      <img src="/images/logo-white.png" alt="" className={s.Logo} />
      <img src="/images/btc.png" alt="" className={s.BTC} />
      <div className={s.Title}>{i18n('forgetPasswordFormTitle')}</div>
    </div>

    <div className={s.FormFields}>
      <img className={s.Icon} src="/images/icons/user-o.png" alt="" />
      <Field
        type="email"
        name="email"
        placeholder={i18n('username')}
        component={Input}
        validate={username}
      />

      <img className={s.Icon} src="/images/icons/security.png" alt="" />
      <div className="d-flex justify-content-between">
        <Field
          name="captcha"
          component={Input}
          type="text"
          placeholder={i18n('captcha')}
          className={s.CaptchaInput}
        />
        <Captcha className={s.Captcha} />
      </div>

      <button className={classNames(s.Submit, 'button-light')} disabled={submitting} type="submit">
        {i18n('submit')}
      </button>
    </div>
  </form>
));
