import { keys } from 'lodash/fp';
import getText from 'relient/get-text';
import getOptions from 'relient/get-options';

export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';
export const ACTIVATING = 'ACTIVATING';

const textMap = {
  [ACTIVE]: 'active',
  [INACTIVE]: 'inactive',
  [ACTIVATING]: 'activating',
};

export const accountStatuses = keys(textMap);
export const getAccountStatusText = getText(textMap);
export const accountStatusOptions = getOptions(textMap);
