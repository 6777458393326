import React from 'react';
import { func, string, object } from 'prop-types';
import { compose, setDisplayName, setPropTypes, pure } from 'recompose';
import { withI18N } from 'shared/contexts';
import { reduxForm, Field } from 'redux-form';
import { REGISTER } from 'shared/constants/form-name';
import { createOnSubmit } from 'relient/form';
import { Button } from 'reactstrap';
import Input from 'shared/components/fields/input';
import { success } from 'shared/components/message';
import withStyles from 'isomorphic-style-loader/withStyles';
import Captcha from 'shared/components/captcha';
import { username } from 'shared/utils/validators';
import classNames from 'classnames';
// eslint-disable-next-line css-modules/no-unused-class
import s from './form.scss';

export default compose(
  withI18N,
  setPropTypes({
    register: func,
    onSuccess: func,
    onLogin: func,
    agencyName: string,
    initialValues: object,
    agencyInvitationCode: string,
    invitationCode: string,
    inviteCode: string,
  }),
  pure,
  reduxForm({
    form: REGISTER,
    onSubmit: createOnSubmit(async (values, _, { register, onSuccess, agencyInvitationCode, invitationCode, inviteCode, i18n }) => {
      const { payload } = await register({ ...values, agencyInvitationCode, invitationCode, inviteCode });
      success(i18n('registerSuccess'));
      onSuccess(payload);
    }),
  }),
  withStyles(s),
  setDisplayName(__filename),
)(({ handleSubmit, onLogin, submitting, agencyName, i18n, inviteCode }) => (
  <form onSubmit={handleSubmit} className={s.Form}>
    <div>
      <img src="/images/logo-white.png" alt="" className={s.Logo} />
      <img src="/images/btc.png" alt="" className={s.BTC} />
      <div className={s.Title}>{i18n('signUp')}</div>
    </div>

    <div className={s.FormFields}>
      {agencyName && (
        <div style={{ color: 'white', marginBottom: 20 }}>
          {i18n('registerFormInvitedBy', { agencyName })}
        </div>
      )}

      <img className={s.Icon} src="/images/icons/user-o.png" alt="" />
      <Field
        name="username"
        component={Input}
        type="email"
        placeholder={i18n('username')}
        validate={username}
      />

      <img className={s.Icon} src="/images/icons/lock.png" alt="" />
      <Field
        name="password"
        component={Input}
        type="password"
        placeholder={i18n('password')}
      />

      <img className={s.Icon} src="/images/icons/lock.png" alt="" />
      <Field
        name="confirmPassword"
        component={Input}
        type="password"
        placeholder={i18n('repeatPassword')}
      />

      <img className={s.Icon} src="/images/icons/mail.png" alt="" />
      <Field
        name="inviteCode"
        component={Input}
        readOnly={!!inviteCode}
        type="text"
        placeholder={i18n('invitationCode')}
      />

      <img className={s.Icon} src="/images/icons/security.png" alt="" />
      <div className="d-flex justify-content-between">
        <Field
          name="captcha"
          component={Input}
          type="text"
          placeholder={i18n('captcha')}
          className={s.CaptchaInput}
        />
        <Captcha className={s.Captcha} />
      </div>
      <button className={classNames(s.Submit, 'button-light')} disabled={submitting} type="submit">
        {i18n('signUp')}
      </button>
      <p>
        {i18n('registerFormHaveAccount')}
        <Button onClick={onLogin}>{i18n('signIn')}</Button>
      </p>
    </div>
  </form>
));
