import { readAll as readAllAgencyContract } from 'shared/actions/agency-contract';
import { readMine as readMyWallet } from 'shared/actions/wallet';
import { readAll as readAllSalesCommissions } from 'shared/actions/sales-commission';
import { readAll as readAllAgency } from 'shared/actions/agency';
import { readOne as readProcessingFee } from 'shared/actions/withdrawal-processing-fee';

export default (account, dispatch, agencyEntity, preloader = []) => [
  ...preloader,
  dispatch(readAllAgencyContract()),
  dispatch(readMyWallet()),
  dispatch(readAllSalesCommissions()),
  dispatch(readAllAgency()),
  dispatch(readProcessingFee()),
];
