import React from 'react';
import { node, string, object } from 'prop-types';
import { connect } from 'react-redux';
import { compose, setDisplayName, setPropTypes, withState, lifecycle, withHandlers } from 'recompose';
import withStyles from 'isomorphic-style-loader/withStyles';
import motion from 'antd/lib/style/core/motion.less';
import qs from 'query-string';
import { prop } from 'lodash/fp';
import {
  toggleLoginModal as toggleLoginModalAction,
  toggleRegisterModal as toggleRegisterModalAction,
  toggleForgetPasswordModal as toggleForgetPasswordModalAction,
  toggleResetPasswordModal as toggleResetPasswordModalAction,
} from 'shared/actions/global';
import {
  Modal,
  ModalBody,
} from 'reactstrap';
import { login as loginAction, logout as logoutAction } from 'shared/actions/auth';
import { push as pushAction } from 'relient/actions/history';
import {
  requestChangePassword as requestChangePasswordAction,
  changePasswordWithoutAccountId as changePasswordWithoutAccountIdAction,
  register as registerAction,
  update as updateAccountAction,
} from 'shared/actions/account';
import CountDown from './count-down';
import LoginForm from './login-form';
import RegisterForm from './register-form';
import ForgetPasswordForm from './forget-password-form';
import ResetPasswordForm from './reset-password-form';
// eslint-disable-next-line css-modules/no-unused-class
import layout from './layout_.scss';
// eslint-disable-next-line css-modules/no-unused-class
import message from '../message_.scss';

import Footer from './footer';
import Header from './header';
import selector from './layout-selector';
import s from './layout.scss';
import { withI18N } from '../../contexts';

export default compose(
  setPropTypes({
    children: node,
    className: string,
    style: object,
    registerEmail: string,
  }),
  withI18N,
  withState('invitationCode', 'setInvitationCode', null),
  withState('agencyInvitationCode', 'setAgencyInvitationCode', null),
  withState('inviteCode', 'setInviteCode', null),
  withState('email', 'setEmail', null),
  lifecycle({
    componentDidMount() {
      this.props.setInvitationCode(
        prop('invitationCode')(qs.parse(global.location.search)),
      );
      this.props.setAgencyInvitationCode(
        prop('agencyInvitationCode')(qs.parse(global.location.search)),
      );
      this.props.setInviteCode(
        prop('inviteCode')(qs.parse(global.location.search)),
      );
    },
  }),
  connect(selector, {
    toggleLoginModal: toggleLoginModalAction,
    toggleRegisterModal: toggleRegisterModalAction,
    toggleForgetPasswordModal: toggleForgetPasswordModalAction,
    toggleResetPasswordModal: toggleResetPasswordModalAction,
    requestChangePassword: requestChangePasswordAction,
    changePasswordWithoutAccountId: changePasswordWithoutAccountIdAction,
    login: loginAction,
    register: registerAction,
    logout: logoutAction,
    push: pushAction,
    updateAccount: updateAccountAction,
  }),
  withHandlers({
    logout: ({ logout }) => () => {
      logout();
      global.location.href = '/';
    },
  }),
  withStyles(layout, motion, message, s),
  setDisplayName(__filename),
)(({
  children,
  className,
  currentAccount,
  currentFeature,
  style,
  isLoginModalOpen,
  isRegisterModalOpen,
  isForgetPasswordModalOpen,
  isResetPasswordModalOpen,
  toggleLoginModal,
  toggleRegisterModal,
  toggleResetPasswordModal,
  toggleForgetPasswordModal,
  login,
  register,
  agencyName,
  isMobileView,
  logout,
  push,
  requestChangePassword,
  changePasswordWithoutAccountId,
  email,
  setEmail,
  registerEmail,
  invitationCode,
  agencyInvitationCode,
  inviteCode,
  hideSubHeader,
  updateAccount,
  language,
  countDown,
}) => (
  <div className={`language-${language}`}>
    <Header
      updateAccount={updateAccount}
      currentAccount={currentAccount}
      currentFeature={currentFeature}
      toggleLoginModal={toggleLoginModal}
      toggleRegisterModal={toggleRegisterModal}
      logout={logout}
      isMobileView={isMobileView}
      hideSubHeader={hideSubHeader}
    />
    <div className={className} style={style}>
      {children}
    </div>
    <Footer />

    <Modal
      isOpen={isLoginModalOpen}
      toggle={toggleLoginModal}
      wrapClassName={s.AuthModal}
      fade={false}
      backdrop={!isMobileView}
    >
      <ModalBody>
        <LoginForm
          login={login}
          onSuccess={toggleLoginModal}
          toggleForgetPasswordModal={toggleForgetPasswordModal}
          onRegister={() => {
            toggleLoginModal();
            toggleRegisterModal();
          }}
          push={push}
        />
        {isMobileView && (
          <div className={s.Back} onClick={toggleLoginModal}>
            <img src="/images/icons/back.png" alt="" />
            BACK
          </div>
        )}
      </ModalBody>
    </Modal>

    <Modal
      isOpen={isForgetPasswordModalOpen}
      toggle={toggleForgetPasswordModal}
      wrapClassName={s.AuthModal}
      fade={false}
      backdrop={!isMobileView}
    >
      <ModalBody>
        <ForgetPasswordForm
          requestChangePassword={requestChangePassword}
          onSuccess={(values) => {
            setEmail(values.email);
            toggleResetPasswordModal();
          }}
        />
        {isMobileView && (
          <div
            className={s.Back}
            onClick={toggleForgetPasswordModal}
          >
            <img src="/images/icons/back.png" alt="" />
            BACK
          </div>
        )}
      </ModalBody>
    </Modal>

    <Modal
      isOpen={isResetPasswordModalOpen}
      toggle={toggleResetPasswordModal}
      wrapClassName={s.AuthModal}
      fade={false}
      backdrop={!isMobileView}
    >
      <ModalBody>
        <ResetPasswordForm
          initialValues={{ email }}
          changePassword={changePasswordWithoutAccountId}
          onSuccess={() => {
            toggleResetPasswordModal();
            toggleForgetPasswordModal();
          }}
        />
      </ModalBody>
    </Modal>

    <Modal
      isOpen={isRegisterModalOpen}
      toggle={toggleRegisterModal}
      wrapClassName={s.AuthModal}
      fade={false}
      backdrop={!isMobileView}
    >
      <ModalBody>
        <RegisterForm
          agencyName={agencyName}
          register={register}
          onSuccess={toggleRegisterModal}
          onLogin={() => {
            toggleLoginModal();
            toggleRegisterModal();
          }}
          invitationCode={invitationCode}
          initialValues={{ username: registerEmail, inviteCode }}
          agencyInvitationCode={agencyInvitationCode}
          inviteCode={inviteCode}
        />
        {isMobileView && (
          <div className={s.Back} onClick={toggleRegisterModal}>
            <img src="/images/icons/back.png" alt="" />
            BACK
          </div>
        )}
      </ModalBody>
    </Modal>
    <CountDown countDown={countDown} currentFeature={currentFeature} />
  </div>
));
