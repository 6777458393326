/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react';
import { object, string, bool } from 'prop-types';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import classNames from 'classnames';
import { withI18N } from 'shared/contexts';

export default compose(
  setPropTypes({
    label: string,
    tips: string,
    placeholder: string,
    type: string.isRequired,
    disabled: bool,
    inputStyle: object,
    size: string,
    className: string,
    readOnly: bool,
    inputClassName: string,
  }),
  withI18N,
  setDisplayName(__filename),
)(({
  input,
  meta: { touched, error },
  tips,
  placeholder,
  type,
  disabled,
  inputStyle,
  size,
  className,
  readOnly,
  label,
  inputClassName,
  i18n,
}) => (
  <div className={classNames('form-group', className)}>
    {label && <label>{label}</label>}
    <input
      {...input}
      style={inputStyle}
      type={type}
      disabled={disabled}
      className={classNames(readOnly ? 'form-control-plaintext' : 'form-control', inputClassName, 'input', {
        'form-control-lg': size === 'large',
        'form-control-sm': size === 'small',
        'is-invalid': touched && error,
      })}
      readOnly={readOnly}
      placeholder={placeholder}
    />
    {tips && <small className="form-text text-muted">{tips}</small>}
    {touched && error && <div className="invalid-feedback">{i18n(error)}</div>}
  </div>
));
