import { prop, flow, find } from 'lodash/fp';
import { getCurrentAccount } from 'shared/selectors/account';
import { getEntityArray, getEntity } from 'relient/selectors';
import isMobileView from 'shared/selectors/is-mobile-view';

export default (state, { invitationCode, agencyInvitationCode }) => ({
  agencyName: flow(
    getEntityArray('agency'),
    find((agency) => agency.invitationCode === invitationCode
      || agency.agencyInvitationCode === agencyInvitationCode),
    prop('name'),
  )(state),
  currentAccount: getCurrentAccount(state),
  currentFeature: prop('global.feature')(state),
  isLoginModalOpen: prop('global.isLoginModalOpen')(state),
  isRegisterModalOpen: prop('global.isRegisterModalOpen')(state),
  isForgetPasswordModalOpen: prop('global.isForgetPasswordModalOpen')(state),
  isResetPasswordModalOpen: prop('global.isResetPasswordModalOpen')(state),
  isMobileView: isMobileView(state),
  countDown: getEntity('misc.countDown')(state),
});
