import {
  sameAsValidator,
  minLengthValidator,
  maxLengthValidator,
  positiveNumberValidator,
  lessThanValidator,
} from 'relient/form';
import { validate } from 'wallet-address-validator';
import { BCH } from 'shared/constants/cryptocurrency-display';
import { isLegacyAddress, isCashAddress } from 'bchaddrjs';
import { startsWith } from 'lodash/fp';

const requiredValidator = message => (value) => {
  if (!value) {
    return message;
  }

  return undefined;
};

export const required = requiredValidator('requiredField');
export const sameAs = sameAsValidator('sameAsError');
export const minLength = minLengthValidator('minLengthError');
export const maxLength = maxLengthValidator('maxLengthError');
export const positiveNumber = positiveNumberValidator('positiveNumberError');
export const lessThan = lessThanValidator('lessThanError');

export const username = [required];
export const password = [required];
export const confirmedPassword = [
  required,
  sameAs('password'),
  minLength(6),
];
export const confirmedNewPassword = [
  required,
  sameAs('newPassword'),
  minLength(6),
];
export const walletAddress = [required, (value, allValues, { cryptocurrencyShortName }) => {
  if (cryptocurrencyShortName === BCH) {
    try {
      if (isLegacyAddress(value)) {
        return undefined;
      }
    } catch (e) {
      // ignore
    }
    try {
      if (isCashAddress(startsWith('bitcoincash:')(value) ? value : `bitcoincash:${value}`)) {
        return undefined;
      }
    } catch (e) {
      // ignore
    }
  }
  if (validate(value, cryptocurrencyShortName)) {
    return undefined;
  }
  return 'addressInvalid';
}];
