exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".rTbzP{position:absolute;left:10px;top:20px;pointer-events:none}@media (max-width: 640px){.rTbzP{width:200px;bottom:40px;top:auto}}._398MZ{color:white;font-size:68px;margin-top:50px;margin-left:50px;text-align:right;width:410px;margin-right:100px}@media (max-width: 640px){._398MZ{color:white;font-size:34px;text-align:center;margin:40px 0 40px}}._2_5R6{width:360px;opacity:0.5;margin-top:40px}@media (max-width: 640px){._2_5R6{width:180px;margin-top:20px}}._2Fn_r{display:flex;padding:90px 60px 40px}@media (max-width: 640px){._2Fn_r{padding:0;display:block}}._2Fn_r ._2Gk7h{position:absolute;color:#00a8ff;height:24px;margin-left:12px;margin-top:12px}._2Fn_r p{margin-top:10px;margin-bottom:0;display:flex;justify-content:space-between;align-items:center;font-size:14px}._2Fn_r p button{background:none;border:none;font-size:18px}._2Fn_r p button:hover{background:none;border:none}._2Fn_r .IkrlF{font-size:20px;display:block;width:100%;box-shadow:-4px 4px 10px #054ea9}._3nPrL{width:100%}@media (max-width: 640px){._3nPrL{padding:0 40px}}@media (max-width: 640px){._1fAUH{width:200px}}._1cmR1{height:48px}@media (max-width: 640px){._1cmR1{height:38px}}\n", ""]);

// exports
exports.locals = {
	"BTC": "rTbzP",
	"Title": "_398MZ",
	"Logo": "_2_5R6",
	"Form": "_2Fn_r",
	"Icon": "_2Gk7h",
	"Submit": "IkrlF",
	"FormFields": "_3nPrL",
	"CaptchaInput": "_1fAUH",
	"Captcha": "_1cmR1"
};