exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3r24g{display:flex;align-items:center;height:76px}@media (max-width: 640px){._3r24g{height:50px}}@media (max-width: 640px){._1BT5m{flex:1 1}}._1BT5m img{width:160px}@media (max-width: 640px){._1BT5m img{width:100px}}.zUnYN{padding:16px 12px;font-size:24px;color:white}@media (max-width: 640px){.zUnYN{padding:18px 8px 12px;font-size:12px}.language-ja_JP .zUnYN{padding:18px 4px 12px;font-size:12px}}.jdlBp{background:#1ba8ff;margin-left:20px;border-radius:0}@media (max-width: 640px){.language-ja_JP .jdlBp{margin-left:2px}}._3sMSC{display:flex;margin:0 40px;flex:1 1;justify-content:flex-end}@media (max-width: 640px){._3sMSC{margin:0;border-top:1px solid rgba(255,255,255,0.1);padding-top:10px}}.G97-3{padding:0 20px;position:relative;color:#c2c6f2}@media (max-width: 640px){.G97-3{flex:1 1;text-align:center}}.G97-3.LJ_9d{color:#fefefe}.G97-3.LJ_9d:hover{color:#fefefe}._2HX6r{padding-top:40px;box-sizing:border-box;height:280px;font-size:50px;text-align:center;color:white;position:relative;font-family:MontserratBold}._2HX6r img{opacity:0.1;position:absolute;z-index:-1;height:400px;left:50%;top:-40px;transform:translateX(-50%)}@media (max-width: 640px){._2HX6r{height:130px;padding-top:20px;font-size:26px}._2HX6r img{height:200px;top:-20px}}\n", ""]);

// exports
exports.locals = {
	"Header": "_3r24g",
	"Logo": "_1BT5m",
	"Auth": "zUnYN",
	"SignUp": "jdlBp",
	"Menu": "_3sMSC",
	"MenuItem": "G97-3",
	"active": "LJ_9d",
	"SubHeader": "_2HX6r"
};