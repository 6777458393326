export const BTC = 'BTC';
export const BCH = 'BCH';
export const ZEC = 'ZEC';

export const currencyMap = {
  [BTC]: {
    color: '#f6b20e',
    icon: 'btc',
  },
  [BCH]: {
    color: '#d9212b',
    icon: 'btc',
  },
  [ZEC]: {
    color: '#6A8759',
    icon: 'btc',
  },
};
