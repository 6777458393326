import React from 'react';
import { string, node, func, bool } from 'prop-types';
import { propEq, omit } from 'lodash/fp';
import { connect } from 'react-redux';
import { compose, setDisplayName, setPropTypes, withHandlers } from 'recompose';
import { push as pushAction, goBack as goBackAction } from 'relient/actions/history';
import { getFeatureBy } from 'shared/constants/features';

const isLeftClickEvent = propEq('button')(0);

const isModifiedEvent = ({
  metaKey,
  altKey,
  ctrlKey,
  shiftKey,
}) => !!(metaKey || altKey || ctrlKey || shiftKey);

const getHref = ({ to, feature }) => {
  if (feature) {
    return getFeatureBy('link')(feature);
  }
  return to;
};

export default compose(
  setPropTypes({
    to: string,
    feature: string,
    children: node,
    onClick: func,
    back: bool,
    showIcon: bool,
  }),
  connect(null, { push: pushAction, goBack: goBackAction }),
  setDisplayName(__filename),
  withHandlers({
    handleClick: ({
      onClick,
      to,
      push,
      target,
      goBack,
      feature,
      back = false,
    }) => (event) => {
      if (back) {
        event.preventDefault();
        goBack();
      }
      if (onClick && onClick(event) === false) {
        event.preventDefault();
        return;
      }
      if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
        return;
      }

      if (event.defaultPrevented === true || target === '_blank') {
        return;
      }

      event.preventDefault();
      push(to || feature);
    },
  }),
)(({ to, children, feature, handleClick, showIcon, ...props }) => (
  <a
    href={getHref({
      to,
      feature,
    })}
    {...omit(['push', 'back', 'goBack'])(props)}
    onClick={handleClick}
  >
    {children}
    {!children && getFeatureBy('text')(feature)}
  </a>
));
