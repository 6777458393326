exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NNPc7 .modal-body{background-image:linear-gradient(90deg, #00e9ff 0%, #172086 0%, #0083ff 100%)}.NNPc7 .modal-dialog{max-width:1100px}@media (max-width: 640px){.NNPc7 .modal-dialog{margin:0;width:100%;height:100%}.NNPc7 .modal-content{width:100%;height:100%}}._3NVm_{position:absolute;right:100px;top:10px;cursor:pointer}@media (max-width: 640px){._3NVm_{position:static;text-align:center;font-size:16px;margin-top:20px}}._3NVm_ img{width:24px;margin-right:8px;margin-bottom:2px}@media (max-width: 640px){._3NVm_ img{width:20px}}\n", ""]);

// exports
exports.locals = {
	"AuthModal": "NNPc7",
	"Back": "_3NVm_"
};