import React from 'react';
import { func } from 'prop-types';
import { compose, setDisplayName, setPropTypes, pure } from 'recompose';
import { withI18N } from 'shared/contexts';
import { reduxForm, Field } from 'redux-form';
import { LOGIN } from 'shared/constants/form-name';
import { createOnSubmit } from 'relient/form';
import { Button } from 'reactstrap';
import Input from 'shared/components/fields/input';
import { success, error } from 'shared/components/message';
import withStyles from 'isomorphic-style-loader/withStyles';
import Captcha from 'shared/components/captcha';
import { CUSTOMER } from 'shared/constants/login-type';
import getPreloader from 'shared/utils/preloader';
import { readAll as readAllAgencyAction } from 'shared/actions/agency';
import { username } from 'shared/utils/validators';
import { ACTIVATING } from 'shared/constants/account-status';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { getEntity } from 'relient/selectors';
// eslint-disable-next-line css-modules/no-unused-class
import s from './form.scss';

export default compose(
  withI18N,
  setPropTypes({
    login: func,
    onSuccess: func,
    onRegister: func,
    toggleForgetPasswordModal: func,
  }),
  connect((state) => ({ agencyEntity: getEntity('agency')(state) })),
  pure,
  reduxForm({
    form: LOGIN,
    onSubmit: createOnSubmit(async (values, dispatch, { login, onSuccess, push, agencyEntity, i18n }) => {
      const payload = await login({ ...values, type: CUSTOMER });
      if (payload.account.status === ACTIVATING) {
        error(i18n('checkActivationEmail'));
      } else if (payload.account.roleId === 3 || payload.account.roleId === 2) {
        await dispatch(readAllAgencyAction());
        await Promise.all(getPreloader(payload.account, dispatch, agencyEntity));
        success(i18n('loginSuccess'));
        onSuccess(payload);
        push('/');
      }
    }),
  }),
  withStyles(s),
  setDisplayName(__filename),
)(({ handleSubmit, onRegister, submitting, toggleForgetPasswordModal, i18n }) => (
  <form onSubmit={handleSubmit} className={s.Form}>
    <div>
      <img src="/images/logo-white.png" alt="" className={s.Logo} />
      <img src="/images/btc.png" alt="" className={s.BTC} />
      <div className={s.Title}>{i18n('signIn')}</div>
    </div>
    <div className={s.FormFields}>
      <img className={s.Icon} src="/images/icons/user-o.png" alt="" />
      <Field
        name="username"
        component={Input}
        type="text"
        placeholder={i18n('username')}
        validate={username}
      />

      <img className={s.Icon} src="/images/icons/lock.png" alt="" />
      <Field
        name="password"
        component={Input}
        type="password"
        placeholder={i18n('password')}
      />

      <img className={s.Icon} src="/images/icons/security.png" alt="" />
      <div className="d-flex justify-content-between">
        <Field
          name="captcha"
          component={Input}
          type="text"
          placeholder={i18n('captcha')}
          className={s.CaptchaInput}
        />
        <Captcha className={s.Captcha} />
      </div>
      <button className={classNames(s.Submit, 'button-light')} disabled={submitting} type="submit">
        {i18n('signIn')}
      </button>
      <p>
        {i18n('loginFormNoAccount')}
        <Button onClick={onRegister}>{i18n('signUp')}</Button>
      </p>
      <p style={{ marginTop: 0 }}>
        {i18n('loginFormForgetPassword')}
        <Button onClick={toggleForgetPasswordModal}>{i18n('reset')}</Button>
      </p>
    </div>
  </form>
));
