import React from 'react';
import { func } from 'prop-types';
import { compose, setDisplayName, setPropTypes, pure } from 'recompose';
import { withI18N } from 'shared/contexts';
import { reduxForm, Field } from 'redux-form';
import { LOGIN } from 'shared/constants/form-name';
import { createOnSubmit } from 'relient/form';
import Input from 'shared/components/fields/input';
import { success } from 'shared/components/message';
import withStyles from 'isomorphic-style-loader/withStyles';
import { username, required, password, confirmedNewPassword } from 'shared/utils/validators';
import classNames from 'classnames';
// eslint-disable-next-line css-modules/no-unused-class
import s from './form.scss';

export default compose(
  withI18N,
  setPropTypes({
    changePassword: func,
    onSuccess: func,
  }),
  pure,
  reduxForm({
    enableReinitialize: true,
    form: LOGIN,
    onSubmit: createOnSubmit(async (values, _, { changePassword, onSuccess, i18n }) => {
      await changePassword(values);
      onSuccess();
      success(i18n('resetPasswordSuccess'));
    }),
  }),
  withStyles(s),
  setDisplayName(__filename),
)(({ handleSubmit, submitting, i18n }) => (
  <form onSubmit={handleSubmit} className={s.Form}>
    <div>
      <img src="/images/logo-white.png" alt="" className={s.Logo} />
      <img src="/images/btc.png" alt="" className={s.BTC} />
      <div className={s.Title}>{i18n('resetPassword')}</div>
    </div>

    <div className={s.FormFields}>
      <Field
        type="email"
        name="email"
        placeholder={i18n('username')}
        component={Input}
        validate={username}
        readOnly
      />

      <Field
        type="text"
        name="code"
        size="large"
        placeholder={i18n('verificationCode')}
        component={Input}
        validate={required}
      />

      <Field
        type="password"
        name="newPassword"
        size="large"
        placeholder={i18n('password')}
        component={Input}
        validate={password}
      />

      <Field
        type="password"
        name="repeatPassword"
        size="large"
        placeholder={i18n('confirmPassword')}
        component={Input}
        validate={confirmedNewPassword}
      />
    </div>

    <button className={classNames(s.Submit, 'button-light')} disabled={submitting} type="submit">
      {i18n('submit')}
    </button>
  </form>
));
