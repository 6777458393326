import React from 'react';
import { object, string, func, bool } from 'prop-types';
import { withI18N } from 'shared/contexts';
import classNames from 'classnames';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withProps,
  withStateHandlers,
  withHandlers,
} from 'recompose';
import withStyles from 'isomorphic-style-loader/withStyles';
import cookie from 'js-cookie';
import {
  HOME,
  PRODUCT_CENTER,
  FINANCIAL_CENTER,
  WALLET,
  getFeatureBy,
} from 'shared/constants/features';
import { LANGUAGE } from 'shared/constants/cookies';
import Link from 'shared/components/link';
import {
  Container,
  Row,
  Col,
  Button, DropdownToggle, DropdownMenu, DropdownItem, Dropdown,
} from 'reactstrap';
import { CUSTOMER, AGENCY } from 'shared/constants/role-key';
import { map, prop } from 'lodash/fp';
import { getLanguageText, languageOptions } from 'shared/constants/languages';
import s from './header.scss';

export default compose(
  withI18N,
  setPropTypes({
    currentAccount: object,
    currentFeature: string,
    toggleLoginModal: func.isRequired,
    updateAccount: func.isRequired,
    toggleRegisterModal: func.isRequired,
    logout: func.isRequired,
    isMobileView: bool,
    hideSubHeader: bool,
  }),
  withHandlers({
    switchLanguage: ({ currentAccount, updateAccount }) => async (language) => {
      if (currentAccount) {
        await updateAccount({ id: currentAccount.id, language });
      }
      cookie.set(LANGUAGE, language, { expires: 60 });
      global.location.reload();
    },
  }),
  withStateHandlers({
    isDropdownOpen: false,
  }, {
    toggleDropdown: ({ isDropdownOpen }) => () => ({ isDropdownOpen: !isDropdownOpen }),
  }),
  withProps(({ currentAccount, isMobileView }) => {
    let features = [
      HOME,
      PRODUCT_CENTER,
    ];
    if (prop('roleKey')(currentAccount) === CUSTOMER) {
      features = isMobileView ? [
        HOME,
        PRODUCT_CENTER,
        FINANCIAL_CENTER,
      ] : [
        HOME,
        PRODUCT_CENTER,
        FINANCIAL_CENTER,
        WALLET,
      ];
    } else if (prop('roleKey')(currentAccount) === AGENCY) {
      features = isMobileView ? [
        HOME,
      ] : [
        HOME,
        WALLET,
      ];
    }

    return {
      features,
    };
  }),
  withStyles(s),
  setDisplayName(__filename),
)(({
  currentAccount,
  currentFeature,
  toggleLoginModal,
  toggleRegisterModal,
  features,
  i18n,
  language,
  logout,
  isMobileView,
  hideSubHeader,
  isDropdownOpen,
  toggleDropdown,
  switchLanguage,
}) => {
  const menu = (
    <div className={s.Menu}>
      {map((feature) => {
        const isActive = feature === currentFeature;
        const text = getFeatureBy('text')(feature);
        return (
          <Link
            className={classNames(s.MenuItem, { [s.active]: isActive })}
            feature={feature}
            key={feature}
          >
            {i18n(text)}
          </Link>
        );
      })(features)}
    </div>
  );

  return (
    <div>
      <Container>
        <Row>
          <Col className={s.Header}>
            <div className={s.Logo}>
              <img src="/images/logo-white.png" alt="logo" />
            </div>

            {!isMobileView && menu}
            {currentAccount ? (
              <Button
                color="ghost"
                className={classNames(s.Auth, s.SignUp)}
                onClick={logout}
              >
                {i18n('signOut')}
              </Button>
            ) : (
              <div>
                <Button
                  color="ghost"
                  className={s.Auth}
                  onClick={toggleLoginModal}
                >
                  {i18n('signIn')}
                </Button>
                <Button
                  color="ghost"
                  className={classNames(s.Auth, s.SignUp)}
                  onClick={toggleRegisterModal}
                >
                  {i18n('signUp')}
                </Button>
              </div>
            )}

            <Dropdown toggle={toggleDropdown} isOpen={isDropdownOpen} style={{ marginLeft: isMobileView ? 10 : 20 }}>
              <DropdownToggle caret size="sm">
                {getLanguageText(language)}
              </DropdownToggle>
              <DropdownMenu>
                {map(({ value, text }) => (
                  <DropdownItem
                    key={value}
                    active={value === language}
                    onClick={() => switchLanguage(value)}
                  >
                    {text}
                  </DropdownItem>
                ))(languageOptions)}
              </DropdownMenu>
            </Dropdown>
          </Col>
        </Row>
        {isMobileView && menu}
        {!hideSubHeader && (
          <div className={s.SubHeader}>
            <img src="/images/home/background.png" alt="" />
            {i18n('headerSubHeader', { br: <br /> })}
          </div>
        )}
      </Container>
    </div>
  );
});
